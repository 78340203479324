import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";
import useDebounce from "../utils/Debounce";
import SpinnerMUI from "./SpinnerMUI";

const CommonSearchField = ({
  list = [], // Default to an empty array if list is undefined
  label,
  placeholder,
  labelKey,
  valKey,
  valueGetter,
  defaultVal = "",
  filterCardStyle = false,
  onFocus,
  loading = false,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option[labelKey],
  });

  const [value, setValue] = useState({ label: "", value: null });
  const debouncedValue = useDebounce(value?.label || "", 500);
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    if (onFocus) onFocus();
  }, [onFocus]);

  useEffect(() => {
    if (debouncedValue) {
      const arr = list
        .filter((item) => {
          const name = item?.[labelKey] || ""; // Ensure name is always a string
          return name.toLowerCase().includes(debouncedValue.toLowerCase());
        })
        .map((item) => ({
          id: item[valKey],
          name: item[labelKey],
        }));

      setSearchResult(arr);
    } else {
      setSearchResult(
        list.map((item) => ({
          id: item[valKey],
          name: item[labelKey],
        }))
      );
    }
  }, [debouncedValue, list, labelKey, valKey]);

  const handleChange = (e) => {
    const newValue = e.target.value || "";
    setValue({ label: newValue, value: newValue });
    valueGetter({ label: newValue, value: newValue });
  };

  return (
    <div className="position-relative">
      <div
        style={{
          position: "absolute",
          right: "10px",
          top: "-3px",
          fontSize: "13px",
          fontWeight: "600",
        }}
        hidden={defaultVal === "" || defaultVal === null}
      >
        {`${placeholder}`}: {defaultVal || ""}
      </div>
      <FormControl
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Autocomplete
          loading={loading}
          filterOptions={filterOptions}
          className={filterCardStyle ? "filter-autocomplete" : ""}
          id="search_particular"
          freeSolo
          size="small"
          options={searchResult} // Ensure this is an array
          sx={{
            width: "100%",
            fontFamily: "Poppins",
            fontSize: "13px",
          }}
          value={value.label} // Only the label should be passed here
          onChange={(event, newValue) => {
            if (newValue) {
              setValue({
                label: newValue[labelKey] || "",
                value: newValue[valKey] || null,
              });
              valueGetter({
                label: newValue[labelKey] || "",
                value: newValue[valKey] || null,
              });
            } else {
              setValue({ label: "", value: null });
              valueGetter({ label: "", value: null });
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                fontSize: "12px",
              }}
              {...props}
            >
              <Typography>{option[labelKey]}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <FormControl fullWidth>
              <TextField
                {...params}
                size="small"
                variant={filterCardStyle ? "standard" : "outlined"}
                multiline
                label={label}
                placeholder={placeholder}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
                maxRows={5}
                value={value.label} // Ensure this is a string
                onChange={handleChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <SpinnerMUI loading={loading} params={params} />
                  ),
                }}
              />
            </FormControl>
          )}
          clearIcon={
            <BackspaceIcon
              sx={{ fontSize: "15px", ml: 0 }}
              onClick={() => {
                setValue({ label: "", value: null });
                valueGetter({ label: "", value: null });
              }}
            />
          }
        />
      </FormControl>
    </div>
  );
};

export default CommonSearchField;
