import React from "react";
import { Box, Container, Grid, IconButton } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../theme/Theme";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { lp_illustration } from "../iconsImports";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../theme/setThemeColor";

const LandingPageIntro = () => {
  const navigate = useNavigate();

  return (
    <Box
      className="landing-bg"
      id="landing-intro"
      sx={{
        backgroundSize: { md: "cover", xs: "contain" },
        height: "max-content",
      }}
    >
      <Container maxWidth="xl">
        <Grid container xs={12} sx={{ px: { md: 8, sm: 1 } }}>
          <Grid md={6} sm={12}>
            <Box container sx={{ mt: 10 }}>
              <div className="lineUp">
                <div
                  className="landing-bg_main_font"
                  style={{ textAlign: "left" }}
                >
                  Welcome To
                </div>
                <div className="landing-bg_brajpay_font">
                  {process.env.REACT_APP_TITLE}
                </div>
              </div>
              <div className="lineUpDelay">
                <div className="landing_intro">
                  New Age Payment Solution Platform For Individuals, Business
                  Owners & Corporations.
                </div>

                <Box
                  component="div"
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    mb: 3,
                  }}
                >
                  <IconButton
                    className="pulse-effect"
                    aria-label="delete"
                    sx={{
                      backgroundColor: "#fff",
                      marginRight: "0.5rem",
                      color: "#E87204",
                    }}
                  >
                    <FacebookRoundedIcon />
                  </IconButton>
                  <IconButton
                    className="pulse-effect"
                    aria-label="delete"
                    sx={{
                      backgroundColor: "#fff",
                      marginRight: "0.5rem",
                      color: "#E87204",
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                  <IconButton
                    className="pulse-effect"
                    aria-label="delete"
                    sx={{
                      backgroundColor: "#fff",
                      marginRight: "0.5rem",
                      color: "#E87204",
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Box>

                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "row",
                      xs: "column",
                    },
                    mb: 2,
                    mt: 3,
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <PrimaryButton
                    variant="contained"
                    size="large"
                    sx={{
                      mr: { xs: 2, md: 2 },
                      mb: { md: 0, xs: 2 },
                      backgroundColor: "#E87204", // Change background color here
                      "&:hover": {
                        backgroundColor: "#4E5555", // Optional: Change hover color if needed
                      },
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login Here
                  </PrimaryButton>
                  <SecondaryButton
                    variant="contained"
                    size="large"
                    className="primary-button"
                    href="https://play.google.com/store/apps/details?id=com.brajpay.cm"
                    target="_blank"
                  >
                    Download App
                  </SecondaryButton>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid
            md={6}
            sm={12}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              mt: 10,
            }}
          >
            <img
              src={lp_illustration}
              alt="illustration"
              width="90%"
              height="auto"
              style={{
                backgroundColor: getEnv() === "PaisaKart" ? "#fff" : "",
                borderRadius: getEnv() === "PaisaKart" ? "50%" : "",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPageIntro;
