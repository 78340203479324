export const primaryLight = () => {
  if (process.env.REACT_APP_TITLE === "Brajpay") {
    return "#E87204";
  }
};

export const getSecondaryColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#4E5555";
  }
};
export const primaryColor = () => {
  if (process.env.REACT_APP_TITLE === "Brajpay") {
    return "#231942";
  }
};
export const secondaryColor = () => {
  if (process.env.REACT_APP_TITLE === "Brajpay") {
    return "#5e548e";
  }
};

export const getTertiaryColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#00bf78";
  }
};

export const getActiveColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#E87204";
  }
};

// export const getSecondaryColor = () => {
//   if (process.env.REACT_APP_TITLE === "BrajPay") {
//     return "#4E5555";
//   }
// };

export const getHoverActive = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#E87204";
  }
};
export const getHoverInActive = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#709775";
  }
};

export const getTableHeadRowColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#E87204";
  }
};
export const blackColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#000000";
  }
};
export const whiteColor = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "#ffffff";
  }
};

export const getEnv = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return "BrajPay";
  }
};

// user icon bg color change functions . . . .
export const getUserColor = (role) => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    if (role === "Asm") {
      return "#E87204";
    } else if (role === "Ad") {
      return "#f48f26";
    } else if (role === "Ret") {
      return "#00BF78";
    } else if (role === "Dd") {
      return "#E87204";
    } else if (role === "Api") {
      return "#ff9800";
    }
  } else {
    if (role === "Asm") {
      return "#f48f26";
    } else if (role === "Ad") {
      return "#3f3f3f";
    } else if (role === "Ret") {
      return "#32AD6C";
    } else if (role === "Dd") {
      return "#00BF78";
    } else if (role === "Api") {
      return "#ff9800";
    }
  }
};

export const randomColors = () => {
  // Array containing colors
  var colors = [
    "rgb(153, 102, 255 , 0.20)",
    "rgb(75, 192, 192 , 0.20)",
    "rgb(255, 204, 86 , 0.20)",
    "rgb(255, 99, 133 , 0.20)",
    "#d3d3d3",
  ];

  // selecting random color
  var random_color = colors[Math.floor(Math.random() * colors.length)];
  return random_color;
};

export const getStatusColor = (status) => {
  const st = status?.toLowerCase();
  if (st === "total") {
    return "#9f86c0";
  }
  if (st === "success" || st === "paid") {
    return "#00bf78";
  }
  if (st === "pending" || st === "post") {
    return "#FFCC56";
  }
  if (st === "failed") {
    return "#DC5F5F";
  }
  if (st === "refund") {
    return "#9F86C0";
  } else {
    return "#DC5F5F";
  }
};

export const getFirmAddress = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return `22 Krishna Nagar Sabji Mandi Rukmani Vihar Mathura U.P. 281001`;
  }
};
export const getFirmContact = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return `+91-9368689819`;
  }
};
export const getFirmEmail = () => {
  if (process.env.REACT_APP_TITLE === "BrajPay") {
    return `info@brajpay.com`;
  }
};

export const getPriorityBg = (priority) => {
  if (priority === "HIGH") {
    return "rgba(211, 47, 47, 0.089)";
  }
  if (priority === "MEDIUM") {
    return "rgb(255, 244, 220)";
  }
  if (priority === "LOW") {
    return "rgb(255 193 7 / 8%)";
  }
};
export const getPriorityColor = (priority) => {
  if (priority === "HIGH") {
    return "rgba(211, 47, 47)";
  }
  if (priority === "MEDIUM") {
    return "rgb(255, 204, 86)";
  }
  if (priority === "LOW") {
    return "rgb(255 193 7)";
  }
};

export const firmMoto = () => {
  return `We help retailers across the country drive more footfall, save time, get valuable business insights, & grow profitability.`;
};
